import React from "react";
import { RawHtml, Img } from "~utils";

/**
 *
 * @param {{
 * section: {
 *  content: string;
 *  section_id: string;
 *  title: string;
 *  image?: {
 *   childImageSharp: {
 *     fluid: GatsbyTypes.FluidImgFragment;
 *   };
 *  };
 * };
 * actions: import('react').ReactNode[]
 * }} props
 */
export default function ContentBlock(props) {
  const { section, actions } = props;
  const image = section.image?.childImageSharp?.fluid;

  return (
    <section id={section.section_id} className="block">
      <h2 className="block-title underline">{section.title}</h2>
      {image && (
        <div className="block-thumbnail">
          <Img fluid={image} alt={section.title} />
        </div>
      )}
      <div className="block-content">
        <RawHtml html={section.content} />
        {actions}
      </div>
    </section>
  );
}
