import React from "react";
import dayjs from "dayjs";
import { useStaticQuery, graphql } from "gatsby";

import { Link, Img, safePrefix } from "~utils";

export const query = graphql`
  query PostFeed {
    posts: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      nodes {
        fields {
          url
          relativeDir
        }
        frontmatter {
          title
          excerpt
          draft
          date
          thumb_img_path {
            childImageSharp {
              fluid(
                maxWidth: 450
                maxHeight: 300
                quality: 100
                cropFocus: CENTER
              ) {
                ...FluidImg
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @param {{
 *  options: {
 *    subfolder?: string,
 *    num?: number,
 *    inverse?: boolean,
 *  },
 * }} props
 */
export default function PostFeed(props) {
  const {
    subfolder,
    num = Number.POSITIVE_INFINITY,
    inverse = false,
  } = props.options;
  /** @type {GatsbyTypes.PostFeedQuery} */
  const data = useStaticQuery(query);

  const posts = data.posts.nodes
    .filter(({ fields, frontmatter }) => {
      const filterCondition =
        (!subfolder || fields.relativeDir.startsWith(subfolder)) &&
        !frontmatter.draft;
      if (inverse) return !filterCondition;
      return filterCondition;
    })
    .sort((a, b) => {
      const bTime = new Date(b?.frontmatter?.date).getTime();
      const aTime = new Date(a?.frontmatter?.date).getTime();
      if (bTime - aTime === 0) {
        return b.fields.url - a.fields.url;
      }
      return bTime - aTime;
    })
    .slice(0, num);

  return (
    <div className="post-feed">
      {posts.map(({ frontmatter, fields }, postIndex) => {
        return (
          <article key={`${fields.url}-${postIndex}`} className="post">
            <div className="post-inside">
              {frontmatter.thumb_img_path && (
                <Link
                  className="post-thumbnail"
                  to={safePrefix(fields.url)}
                  rel={frontmatter.draft ? "nofollow" : null}
                >
                  <Img
                    alt={frontmatter.title}
                    className="thumbnail"
                    fluid={frontmatter.thumb_img_path?.childImageSharp?.fluid}
                  />
                </Link>
              )}
              <header className="post-header">
                <h3 className="post-title">
                  <Link
                    to={safePrefix(fields.url)}
                    rel={`bookmark ${frontmatter.draft ? "nofollow" : ""}`}
                  >
                    {frontmatter.title}
                  </Link>
                </h3>
              </header>
              <div className="post-content">
                <p>{frontmatter.excerpt}</p>
              </div>
              <footer className="post-meta">
                <time
                  className="published"
                  dateTime={dayjs(frontmatter.date).format("YYYY-MM-DD HH:mm")}
                >
                  {dayjs(frontmatter.date).format("MMMM DD, YYYY")}
                </time>
              </footer>
            </div>
          </article>
        );
      })}
    </div>
  );
}
