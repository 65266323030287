import React from "react";
import PostFeed from "./PostFeed";

export default function PostsBlock(props) {
  const { section } = props;

  return (
    <section id={section.section_id} className="block">
      <h2 className="block-title underline">{section.title}</h2>
      <PostFeed
        options={{
          subfolder: "blog",
          num: section.num_posts_displayed || 4,
        }}
      />
      {props.actions}
    </section>
  );
}
