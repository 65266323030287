import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { RawHtml } from "~utils";

export const query = graphql`
  query Hero {
    siteData {
      data {
        author {
          name
        }
      }
    }
  }
`;

/**
 * @param {{
 *   section: {
 *    section_id: string,
 *    title: string,
 *    content: string,
 *  }
 * }} props
 */
export default function HeroBlock(props) {
  const { section } = props;
  /** @type {GatsbyTypes.HeroQuery} */
  const data = useStaticQuery(query);
  return (
    <section id={section.section_id} className="hero">
      <h2 className="hero-title">
        {section.title
          ? section.title
          : `Hi, I'm ${data.siteData.data.author.name}.`}
      </h2>
      <div className="hero-text">
        <RawHtml html={section.content} />
      </div>
    </section>
  );
}
