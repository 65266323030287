import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { RawHtml, Link } from "~utils";

export const query = graphql`
  query Footer {
    site {
      siteMetadata {
        footer {
          content
          links {
            url
            text
            external
            display
          }
        }
      }
    }
  }
`;

export default function Footer(props) {
  const [handler, setHandler] = useState(() => () => {});
  /** @type {GatsbyTypes.FooterQuery} */
  const data = useStaticQuery(query);

  useEffect(() => {
    setHandler(() => e => {
      e.preventDefault();
      document.querySelector("#page").scrollIntoView({ behavior: "smooth" });
    });
  }, []);

  const { content, links } = data.site.siteMetadata?.footer;

  return (
    <footer id="colophon" className="site-footer">
      <div className="site-info">
        <RawHtml html={content} />
        &nbsp;
        {links
          .filter(link => link.display)
          .map((link, linkIndex) => (
            <React.Fragment key={`${link.url}-${linkIndex}`}>
              <Link to={link.url} external={link.external}>
                {link.text}
              </Link>
              .
            </React.Fragment>
          ))}
      </div>

      <Link id="to-top" className="to-top" to="#page" onClick={handler}>
        <span className="icon-arrow-up" aria-hidden="true" />
        <span className="screen-reader-text">Back to top</span>
      </Link>
    </footer>
  );
}
