import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
      }
    }
    siteData {
      data {
        social {
          links {
            type
            title
            icon
            url
          }
        }
        author {
          name
        }
      }
    }
  }
`;

/**
 * @param {{
 *  frontmatter: GatsbyTypes.PageDataFragment['page']['frontmatter'],
 *  url: string,
 * }} props
 */
export default function SEO(props) {
  const { frontmatter, url: slug } = props;
  /** @type {GatsbyTypes.SEOQuery} */
  const { site, siteData } = useStaticQuery(query);

  const title = frontmatter.title;
  const metaDescription =
    frontmatter?.subtitle ?? site.siteMetadata.description;
  const metaKeywords =
    frontmatter?.keywords?.length > 0
      ? frontmatter?.keywords
      : site.siteMetadata.keywords;
  const url = `${site.siteMetadata.siteUrl}${slug}`;
  const metaImage = `${url}twitter-card.jpg`;
  const hasTwitter = Boolean(frontmatter?.template && !frontmatter?.mock);

  const twitter = siteData.data.social.links.find(
    (social) => social.type === "twitter",
  );
  const twitterUsername = twitter?.url.split("/").slice(-1)[0];
  const canonicalUrl = frontmatter?.canonical_url || url;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      link={[
        canonicalUrl && {
          rel: "canonical",
          href: canonicalUrl,
        },
      ].filter(Boolean)}
      meta={[
        {
          charSet: "utf-8",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        ...(frontmatter?.noindex
          ? [{ name: "robots", content: "noindex" }]
          : []),
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: metaKeywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:creator`,
          content: twitterUsername
            ? `@${twitterUsername}`
            : siteData.data.author.name,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(metaImage && hasTwitter
          ? [
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
              {
                property: "twitter:image",
                content: metaImage,
              },
              // @see https://github.com/syntra/gatsby-remark-social-cards/blob/aaae58a050fd7816a060d53e3cbc5fdd8a7d174c/src/index.js#L86-L87
              {
                property: "og:image:width",
                content: "600",
              },
              {
                property: "og:image:height",
                content: "314",
              },
            ]
          : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]),
      ]}
    />
  );
}
