import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  faTwitter,
  faInstagram,
  faGithub,
  faLinkedin,
  faDev,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";

import { Icon, Link } from "~utils";

const icons = {
  faTwitter,
  faInstagram,
  faGithub,
  faLinkedin,
  faDev,
  faEnvelopeOpenText,
};

export const query = graphql`
  query Social {
    siteData {
      data {
        social {
          links {
            type
            title
            icon
            url
          }
        }
      }
    }
  }
`;

export default function Social(props) {
  /** @type {GatsbyTypes.SocialQuery} */
  const data = useStaticQuery(query);
  const socialLinks = data.siteData.data.social.links;

  return (
    <div className="social-links">
      {socialLinks.map((link, linkIndex) => (
        <Link
          key={`${link.url}-${linkIndex}`}
          to={link.url}
          target="_blank"
          rel="noopener"
        >
          <Icon
            className="fab"
            fontSize="16px"
            height="1em"
            style={{
              lineHeight: 1.875,
            }}
            name={link.title}
            icon={icons[link.icon]}
          />
          <span className="screen-reader-text">{link.title}</span>
        </Link>
      ))}
    </div>
  );
}
