import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Toggle from "react-toggle";
import useDarkMode from "use-dark-mode";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

import { Icon } from "~utils";
import SEO from "./SEO";
import Header from "./Header";
import Footer from "./Footer";

export const query = graphql`
  fragment PageData on Query {
    ...HeaderImg
    page: markdownRemark(fields: { url: { eq: $url } }) {
      fields {
        url
      }
      frontmatter {
        title
        subtitle
        keywords
        template
        canonical_url
        noindex
      }
    }
  }
  query Layout {
    site {
      siteMetadata {
        layout_style
        palette
      }
    }
  }
`;

/**
 *
 * @param {{
 *  pageData: GatsbyTypes.PageDataFragment,
 *  children: import('react').ReactNode,
 * }} props
 */
export default function Layout(props) {
  const { children, pageData } = props;

  /** @type {GatsbyTypes.LayoutQuery} */
  const data = useStaticQuery(query);

  const darkMode = useDarkMode(false, {
    classNameDark: "dark",
    classNameLight: "light",
    // eslint-disable-next-line no-undef
    element: globalThis?.document?.documentElement,
  });

  const frontmatter = pageData.page.frontmatter;
  const url = pageData.page.fields.url;
  const { palette, layout_style } = data.site.siteMetadata;

  return (
    <React.Fragment>
      <SEO frontmatter={frontmatter} url={url} />
      <div
        id="page"
        className={`site style-${layout_style} palette-${palette}`}
      >
        <Header
          frontmatter={frontmatter}
          url={url}
          image={pageData.header?.childImageSharp?.fluid}
        />
        <div id="theme-switcher">
          {
            // eslint-disable-next-line no-undef
            globalThis?.document?.documentElement ? (
              <Toggle
                aria-label="Dark/Light Mode Toggle"
                checked={darkMode.value}
                icons={{
                  checked: (
                    <Icon className="fas" fontSize="12px" icon={faMoon} />
                  ),
                  unchecked: (
                    <Icon className="fas" fontSize="12px" icon={faSun} />
                  ),
                }}
                onChange={darkMode.toggle}
              />
            ) : null
          }
        </div>
        <div id="content" className="site-content">
          <div className="inner">
            <main id="main" className="site-main">
              {children}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
