import React, { useEffect } from "react";

function PdfViewer(props) {
  const { width, height, actions, section } = props;

  useEffect(() => {
    import(
      /* webpackChunkName: "pdf-object" */
      "pdfobject"
    ).then(({ default: PDFObject }) => {
      PDFObject.embed(section.pdf, `#${section.section_id} .pdf-viewer`, {
        PDFJS_URL: "/pdf-viewer/index.html",
        forcePDFJS: true,
      });
    });
  }, [section]);

  return (
    <section id={section.section_id} className="block">
      <div className="pdf-viewer" style={{ width, height }}>
        Loading...
      </div>
      <div className="block-content">{actions}</div>
    </section>
  );
}

PdfViewer.defaultProps = {
  width: "100%",
};

export default PdfViewer;
