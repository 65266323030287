import ContentBlock from "./ContentBlock";
import DeckHelpers from "./DeckHelpers";
import Footer from "./Footer";
import Header from "./Header";
import HeroBlock from "./HeroBlock";
import PdfViewer from "./PdfViewer";
import PostsBlock from "./PostsBlock";
import PostFeed from "./PostFeed";
import SEO from "./SEO";
import Social from "./Social";
import Layout from "./Layout";

export {
  ContentBlock,
  DeckHelpers,
  Footer,
  Header,
  HeroBlock,
  PdfViewer,
  PostFeed,
  PostsBlock,
  SEO,
  Social,
  Layout,
};

export default {
  ContentBlock,
  DeckHelpers,
  Footer,
  Header,
  HeroBlock,
  PdfViewer,
  PostFeed,
  PostsBlock,
  SEO,
  Social,
  Layout,
};
