import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Link, Img, safePrefix } from "~utils";
import Social from "./Social";

export const query = graphql`
  fragment HeaderImg on Query {
    header: file(relativePath: { eq: $headerImg }) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 80) {
          ...FluidImg64
        }
      }
    }
  }
  query Header {
    siteData {
      data {
        menus {
          main {
            title
            weight
            identifier
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        header {
          bg
          title
          tagline
          profile_img
          has_nav
          has_social
        }
      }
    }
  }
`;

/**
 * @type {React.ChangeEventHandler<HTMLInputElement>}
 */
function dummyHandler(e) {}

/**
 *
 * @param {{
 *   url: string,
 *   frontmatter: any,
 *   image: any,
 * }} props
 */
export default function Header(props) {
  /** @type {GatsbyTypes.HeaderQuery} */
  const data = useStaticQuery(query);
  const { url, frontmatter, image } = props;
  const { header } = data.site.siteMetadata;
  const menu = data.siteData.data.menus.main.sort((a, b) => {
    return a.weight - b.weight;
  });

  const [handleMenuClick, setHandler] = useState(() => dummyHandler);

  useEffect(() => {
    document.body.classList.remove("menu--opened");
    setHandler(() => e => {
      if (e.target.checked) {
        document.body.classList.add("menu--opened");
      } else {
        document.body.classList.remove("menu--opened");
      }
    });
  }, []);

  return (
    <header id="masthead" className={"site-header " + header.bg}>
      <div className="site-header-wrap">
        <div className="site-header-inside">
          <div className="site-branding">
            {header.profile_img && (
              <div className="profile">
                <Link to={safePrefix("/")}>
                  <Img
                    fluid={image}
                    src={safePrefix(header.profile_img)}
                    className="avatar"
                    alt="Author Avatar"
                  />
                </Link>
              </div>
            )}
            <div className="site-identity">
              {frontmatter.template === "lego" ||
              frontmatter.template === "listing" ? (
                <h1 className="site-title">
                  <Link to={safePrefix("/")}>{header.title}</Link>
                </h1>
              ) : (
                <p className="site-title">
                  <Link to={safePrefix("/")}>{header.title}</Link>
                </p>
              )}
              {header.tagline && (
                <p className="site-description">{header.tagline}</p>
              )}
            </div>
          </div>
          {header.has_nav && (
            <>
              <input
                id="menu-toggle"
                className="menu-toggle"
                type="checkbox"
                aria-label="Menu Toggle"
                onChange={handleMenuClick}
              />
              <span className="icon-menu" aria-hidden="true" />
              <span className="screen-reader-text">Menu</span>
              <nav
                id="main-navigation"
                className="site-navigation"
                aria-label="Main Navigation"
              >
                <div className="site-nav-wrap">
                  <div className="site-nav-inside">
                    <ul className="menu">
                      {menu.map((item, itemIndex) => (
                        <li
                          key={`${item.identifier}-${itemIndex}`}
                          className={
                            "menu-item " +
                            (url === item.url ? " current-menu-item" : "")
                          }
                        >
                          <Link to={safePrefix(item.url)}>{item.title}</Link>
                        </li>
                      ))}
                    </ul>
                    {header.has_social && <Social />}
                  </div>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
